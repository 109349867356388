import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M12.75 7.09a3 3 0 0 1 2.16 2.16', key: '1d4wjd' }],
  [
    'path',
    {
      d: 'M17.072 17.072c-1.634 2.17-3.527 3.912-4.471 4.727a1 1 0 0 1-1.202 0C9.539 20.193 4 14.993 4 10a8 8 0 0 1 1.432-4.568',
      key: '12yil7',
    },
  ],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  ['path', { d: 'M8.475 2.818A8 8 0 0 1 20 10c0 1.183-.31 2.377-.81 3.533', key: 'lhrkcz' }],
  ['path', { d: 'M9.13 9.13a3 3 0 0 0 3.74 3.74', key: '13wojd' }],
];

/**
 * @component @name MapPinOff
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIuNzUgNy4wOWEzIDMgMCAwIDEgMi4xNiAyLjE2IiAvPgogIDxwYXRoIGQ9Ik0xNy4wNzIgMTcuMDcyYy0xLjYzNCAyLjE3LTMuNTI3IDMuOTEyLTQuNDcxIDQuNzI3YTEgMSAwIDAgMS0xLjIwMiAwQzkuNTM5IDIwLjE5MyA0IDE0Ljk5MyA0IDEwYTggOCAwIDAgMSAxLjQzMi00LjU2OCIgLz4KICA8cGF0aCBkPSJtMiAyIDIwIDIwIiAvPgogIDxwYXRoIGQ9Ik04LjQ3NSAyLjgxOEE4IDggMCAwIDEgMjAgMTBjMCAxLjE4My0uMzEgMi4zNzctLjgxIDMuNTMzIiAvPgogIDxwYXRoIGQ9Ik05LjEzIDkuMTNhMyAzIDAgMCAwIDMuNzQgMy43NCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/map-pin-off
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const MapPinOff = createLucideIcon('map-pin-off', __iconNode);

export default MapPinOff;
