import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'm10 20-1.25-2.5L6 18', key: '18frcb' }],
  ['path', { d: 'M10 4 8.75 6.5 6 6', key: '7mghy3' }],
  ['path', { d: 'M10.585 15H10', key: '4nqulp' }],
  ['path', { d: 'M2 12h6.5L10 9', key: 'kv9z4n' }],
  ['path', { d: 'M20 14.54a4 4 0 1 1-4 0V4a2 2 0 0 1 4 0z', key: 'yu0u2z' }],
  ['path', { d: 'm4 10 1.5 2L4 14', key: 'k9enpj' }],
  ['path', { d: 'm7 21 3-6-1.5-3', key: 'j8hb9u' }],
  ['path', { d: 'm7 3 3 6h2', key: '1bbqgq' }],
];

/**
 * @component @name ThermometerSnowflake
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTAgMjAtMS4yNS0yLjVMNiAxOCIgLz4KICA8cGF0aCBkPSJNMTAgNCA4Ljc1IDYuNSA2IDYiIC8+CiAgPHBhdGggZD0iTTEwLjU4NSAxNUgxMCIgLz4KICA8cGF0aCBkPSJNMiAxMmg2LjVMMTAgOSIgLz4KICA8cGF0aCBkPSJNMjAgMTQuNTRhNCA0IDAgMSAxLTQgMFY0YTIgMiAwIDAgMSA0IDB6IiAvPgogIDxwYXRoIGQ9Im00IDEwIDEuNSAyTDQgMTQiIC8+CiAgPHBhdGggZD0ibTcgMjEgMy02LTEuNS0zIiAvPgogIDxwYXRoIGQ9Im03IDMgMyA2aDIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/thermometer-snowflake
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ThermometerSnowflake = createLucideIcon('thermometer-snowflake', __iconNode);

export default ThermometerSnowflake;
