import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M12 2v5', key: 'nd4vlx' }],
  ['path', { d: 'M6 7h12l4 9H2l4-9Z', key: '123d64' }],
  ['path', { d: 'M9.17 16a3 3 0 1 0 5.66 0', key: '1061mw' }],
];

/**
 * @component @name LampCeiling
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMnY1IiAvPgogIDxwYXRoIGQ9Ik02IDdoMTJsNCA5SDJsNC05WiIgLz4KICA8cGF0aCBkPSJNOS4xNyAxNmEzIDMgMCAxIDAgNS42NiAwIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/lamp-ceiling
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const LampCeiling = createLucideIcon('lamp-ceiling', __iconNode);

export default LampCeiling;
