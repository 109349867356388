import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'm18 14 4 4-4 4', key: '10pe0f' }],
  ['path', { d: 'm18 2 4 4-4 4', key: 'pucp1d' }],
  ['path', { d: 'M2 18h1.973a4 4 0 0 0 3.3-1.7l5.454-8.6a4 4 0 0 1 3.3-1.7H22', key: '1ailkh' }],
  ['path', { d: 'M2 6h1.972a4 4 0 0 1 3.6 2.2', key: 'km57vx' }],
  ['path', { d: 'M22 18h-6.041a4 4 0 0 1-3.3-1.8l-.359-.45', key: 'os18l9' }],
];

/**
 * @component @name Shuffle
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTggMTQgNCA0LTQgNCIgLz4KICA8cGF0aCBkPSJtMTggMiA0IDQtNCA0IiAvPgogIDxwYXRoIGQ9Ik0yIDE4aDEuOTczYTQgNCAwIDAgMCAzLjMtMS43bDUuNDU0LTguNmE0IDQgMCAwIDEgMy4zLTEuN0gyMiIgLz4KICA8cGF0aCBkPSJNMiA2aDEuOTcyYTQgNCAwIDAgMSAzLjYgMi4yIiAvPgogIDxwYXRoIGQ9Ik0yMiAxOGgtNi4wNDFhNCA0IDAgMCAxLTMuMy0xLjhsLS4zNTktLjQ1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/shuffle
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Shuffle = createLucideIcon('shuffle', __iconNode);

export default Shuffle;
