import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M12 22v-7l-2-2', key: 'eqv9mc' }],
  ['path', { d: 'M17 8v.8A6 6 0 0 1 13.8 20H10A6.5 6.5 0 0 1 7 8a5 5 0 0 1 10 0Z', key: 'ubcgy' }],
  ['path', { d: 'm14 14-2 2', key: '847xa2' }],
];

/**
 * @component @name Shrub
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMjJ2LTdsLTItMiIgLz4KICA8cGF0aCBkPSJNMTcgOHYuOEE2IDYgMCAwIDEgMTMuOCAyMEgxMEE2LjUgNi41IDAgMCAxIDcgOGE1IDUgMCAwIDEgMTAgMFoiIC8+CiAgPHBhdGggZD0ibTE0IDE0LTIgMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/shrub
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Shrub = createLucideIcon('shrub', __iconNode);

export default Shrub;
