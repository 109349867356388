import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  [
    'path',
    {
      d: 'M7 12h13a1 1 0 0 1 1 1 5 5 0 0 1-5 5h-.598a.5.5 0 0 0-.424.765l1.544 2.47a.5.5 0 0 1-.424.765H5.402a.5.5 0 0 1-.424-.765L7 18',
      key: 'kc4kqr',
    },
  ],
  ['path', { d: 'M8 18a5 5 0 0 1-5-5V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8', key: '1tqs57' }],
];

/**
 * @component @name Toilet
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNyAxMmgxM2ExIDEgMCAwIDEgMSAxIDUgNSAwIDAgMS01IDVoLS41OThhLjUuNSAwIDAgMC0uNDI0Ljc2NWwxLjU0NCAyLjQ3YS41LjUgMCAwIDEtLjQyNC43NjVINS40MDJhLjUuNSAwIDAgMS0uNDI0LS43NjVMNyAxOCIgLz4KICA8cGF0aCBkPSJNOCAxOGE1IDUgMCAwIDEtNS01VjRhMiAyIDAgMCAxIDItMmg4YTIgMiAwIDAgMSAyIDJ2OCIgLz4KPC9zdmc+) - https://lucide.dev/icons/toilet
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Toilet = createLucideIcon('toilet', __iconNode);

export default Toilet;
