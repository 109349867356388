import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M10 4 8 6', key: '1rru8s' }],
  ['path', { d: 'M17 19v2', key: 'ts1sot' }],
  ['path', { d: 'M2 12h20', key: '9i4pu4' }],
  ['path', { d: 'M7 19v2', key: '12npes' }],
  [
    'path',
    {
      d: 'M9 5 7.621 3.621A2.121 2.121 0 0 0 4 5v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-5',
      key: '14ym8i',
    },
  ],
];

/**
 * @component @name Bath
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgNCA4IDYiIC8+CiAgPHBhdGggZD0iTTE3IDE5djIiIC8+CiAgPHBhdGggZD0iTTIgMTJoMjAiIC8+CiAgPHBhdGggZD0iTTcgMTl2MiIgLz4KICA8cGF0aCBkPSJNOSA1IDcuNjIxIDMuNjIxQTIuMTIxIDIuMTIxIDAgMCAwIDQgNXYxMmEyIDIgMCAwIDAgMiAyaDEyYTIgMiAwIDAgMCAyLTJ2LTUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/bath
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Bath = createLucideIcon('bath', __iconNode);

export default Bath;
