import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'm2.37 11.223 8.372-6.777a2 2 0 0 1 2.516 0l8.371 6.777', key: 'f1wd0e' }],
  ['path', { d: 'M21 15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-5.25', key: '1pfu07' }],
  ['path', { d: 'M3 15a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h9', key: '1oq9qw' }],
  ['path', { d: 'm6.67 15 6.13 4.6a2 2 0 0 0 2.8-.4l3.15-4.2', key: '1fnwu5' }],
  ['rect', { width: '20', height: '4', x: '2', y: '11', rx: '1', key: 'itshg' }],
];

/**
 * @component @name Sandwich
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMi4zNyAxMS4yMjMgOC4zNzItNi43NzdhMiAyIDAgMCAxIDIuNTE2IDBsOC4zNzEgNi43NzciIC8+CiAgPHBhdGggZD0iTTIxIDE1YTEgMSAwIDAgMSAxIDF2MmExIDEgMCAwIDEtMSAxaC01LjI1IiAvPgogIDxwYXRoIGQ9Ik0zIDE1YTEgMSAwIDAgMC0xIDF2MmExIDEgMCAwIDAgMSAxaDkiIC8+CiAgPHBhdGggZD0ibTYuNjcgMTUgNi4xMyA0LjZhMiAyIDAgMCAwIDIuOC0uNGwzLjE1LTQuMiIgLz4KICA8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iNCIgeD0iMiIgeT0iMTEiIHJ4PSIxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/sandwich
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Sandwich = createLucideIcon('sandwich', __iconNode);

export default Sandwich;
