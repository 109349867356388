import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M10.17 4.193a2 2 0 0 1 3.666.013', key: 'pltmmw' }],
  ['path', { d: 'M14 21h2', key: 'v4qezv' }],
  ['path', { d: 'm15.874 7.743 1 1.732', key: '10m0iw' }],
  ['path', { d: 'm18.849 12.952 1 1.732', key: 'zadnam' }],
  ['path', { d: 'M21.824 18.18a2 2 0 0 1-1.835 2.824', key: 'fvwuk4' }],
  ['path', { d: 'M4.024 21a2 2 0 0 1-1.839-2.839', key: '1e1kah' }],
  ['path', { d: 'm5.136 12.952-1 1.732', key: '1u4ldi' }],
  ['path', { d: 'M8 21h2', key: 'i9zjee' }],
  ['path', { d: 'm8.102 7.743-1 1.732', key: '1zzo4u' }],
];

/**
 * @component @name TriangleDashed
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAuMTcgNC4xOTNhMiAyIDAgMCAxIDMuNjY2LjAxMyIgLz4KICA8cGF0aCBkPSJNMTQgMjFoMiIgLz4KICA8cGF0aCBkPSJtMTUuODc0IDcuNzQzIDEgMS43MzIiIC8+CiAgPHBhdGggZD0ibTE4Ljg0OSAxMi45NTIgMSAxLjczMiIgLz4KICA8cGF0aCBkPSJNMjEuODI0IDE4LjE4YTIgMiAwIDAgMS0xLjgzNSAyLjgyNCIgLz4KICA8cGF0aCBkPSJNNC4wMjQgMjFhMiAyIDAgMCAxLTEuODM5LTIuODM5IiAvPgogIDxwYXRoIGQ9Im01LjEzNiAxMi45NTItMSAxLjczMiIgLz4KICA8cGF0aCBkPSJNOCAyMWgyIiAvPgogIDxwYXRoIGQ9Im04LjEwMiA3Ljc0My0xIDEuNzMyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/triangle-dashed
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const TriangleDashed = createLucideIcon('triangle-dashed', __iconNode);

export default TriangleDashed;
