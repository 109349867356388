import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M10.268 21a2 2 0 0 0 3.464 0', key: 'vwvbt9' }],
  ['path', { d: 'M15 8h6', key: '8ybuxh' }],
  ['path', { d: 'M18 5v6', key: 'g5ayrv' }],
  [
    'path',
    {
      d: 'M20.002 14.464a9 9 0 0 0 .738.863A1 1 0 0 1 20 17H4a1 1 0 0 1-.74-1.673C4.59 13.956 6 12.499 6 8a6 6 0 0 1 8.75-5.332',
      key: '1abcvy',
    },
  ],
];

/**
 * @component @name BellPlus
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAuMjY4IDIxYTIgMiAwIDAgMCAzLjQ2NCAwIiAvPgogIDxwYXRoIGQ9Ik0xNSA4aDYiIC8+CiAgPHBhdGggZD0iTTE4IDV2NiIgLz4KICA8cGF0aCBkPSJNMjAuMDAyIDE0LjQ2NGE5IDkgMCAwIDAgLjczOC44NjNBMSAxIDAgMCAxIDIwIDE3SDRhMSAxIDAgMCAxLS43NC0xLjY3M0M0LjU5IDEzLjk1NiA2IDEyLjQ5OSA2IDhhNiA2IDAgMCAxIDguNzUtNS4zMzIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/bell-plus
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const BellPlus = createLucideIcon('bell-plus', __iconNode);

export default BellPlus;
