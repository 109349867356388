import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M17 12H3a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h14', key: '1mb5g1' }],
  ['path', { d: 'M18 8c0-2.5-2-2.5-2-5', key: '1il607' }],
  ['path', { d: 'M21 16a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1', key: '1yl5r7' }],
  ['path', { d: 'M22 8c0-2.5-2-2.5-2-5', key: '1gah44' }],
  ['path', { d: 'M7 12v4', key: 'jqww69' }],
];

/**
 * @component @name Cigarette
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcgMTJIM2ExIDEgMCAwIDAtMSAxdjJhMSAxIDAgMCAwIDEgMWgxNCIgLz4KICA8cGF0aCBkPSJNMTggOGMwLTIuNS0yLTIuNS0yLTUiIC8+CiAgPHBhdGggZD0iTTIxIDE2YTEgMSAwIDAgMCAxLTF2LTJhMSAxIDAgMCAwLTEtMSIgLz4KICA8cGF0aCBkPSJNMjIgOGMwLTIuNS0yLTIuNS0yLTUiIC8+CiAgPHBhdGggZD0iTTcgMTJ2NCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/cigarette
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Cigarette = createLucideIcon('cigarette', __iconNode);

export default Cigarette;
