import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M10 21v-1', key: '1u8rkd' }],
  ['path', { d: 'M10 4V3', key: 'pkzwkn' }],
  ['path', { d: 'M10 9a3 3 0 0 0 0 6', key: 'gv75dk' }],
  ['path', { d: 'm14 20 1.25-2.5L18 18', key: '1chtki' }],
  ['path', { d: 'm14 4 1.25 2.5L18 6', key: '1b4wsy' }],
  ['path', { d: 'm17 21-3-6 1.5-3H22', key: 'o5qa3v' }],
  ['path', { d: 'm17 3-3 6 1.5 3', key: '11697g' }],
  ['path', { d: 'M2 12h1', key: '1uaihz' }],
  ['path', { d: 'm20 10-1.5 2 1.5 2', key: '1swlpi' }],
  ['path', { d: 'm3.64 18.36.7-.7', key: '105rm9' }],
  ['path', { d: 'm4.34 6.34-.7-.7', key: 'd3unjp' }],
];

/**
 * @component @name SunSnow
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMjF2LTEiIC8+CiAgPHBhdGggZD0iTTEwIDRWMyIgLz4KICA8cGF0aCBkPSJNMTAgOWEzIDMgMCAwIDAgMCA2IiAvPgogIDxwYXRoIGQ9Im0xNCAyMCAxLjI1LTIuNUwxOCAxOCIgLz4KICA8cGF0aCBkPSJtMTQgNCAxLjI1IDIuNUwxOCA2IiAvPgogIDxwYXRoIGQ9Im0xNyAyMS0zLTYgMS41LTNIMjIiIC8+CiAgPHBhdGggZD0ibTE3IDMtMyA2IDEuNSAzIiAvPgogIDxwYXRoIGQ9Ik0yIDEyaDEiIC8+CiAgPHBhdGggZD0ibTIwIDEwLTEuNSAyIDEuNSAyIiAvPgogIDxwYXRoIGQ9Im0zLjY0IDE4LjM2LjctLjciIC8+CiAgPHBhdGggZD0ibTQuMzQgNi4zNC0uNy0uNyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/sun-snow
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const SunSnow = createLucideIcon('sun-snow', __iconNode);

export default SunSnow;
