import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M10 10v7.9', key: 'm8g9tt' }],
  ['path', { d: 'M11.802 6.145a5 5 0 0 1 6.053 6.053', key: 'dn87i3' }],
  ['path', { d: 'M14 6.1v2.243', key: '1kzysn' }],
  [
    'path',
    { d: 'm15.5 15.571-.964.964a5 5 0 0 1-7.071 0 5 5 0 0 1 0-7.07l.964-.965', key: '3sxy18' },
  ],
  [
    'path',
    {
      d: 'M16 7V3a1 1 0 0 1 1.707-.707 2.5 2.5 0 0 0 2.152.717 1 1 0 0 1 1.131 1.131 2.5 2.5 0 0 0 .717 2.152A1 1 0 0 1 21 8h-4',
      key: 'gpb6xx',
    },
  ],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  [
    'path',
    {
      d: 'M8 17v4a1 1 0 0 1-1.707.707 2.5 2.5 0 0 0-2.152-.717 1 1 0 0 1-1.131-1.131 2.5 2.5 0 0 0-.717-2.152A1 1 0 0 1 3 16h4',
      key: 'qexcha',
    },
  ],
];

/**
 * @component @name CandyOff
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMTB2Ny45IiAvPgogIDxwYXRoIGQ9Ik0xMS44MDIgNi4xNDVhNSA1IDAgMCAxIDYuMDUzIDYuMDUzIiAvPgogIDxwYXRoIGQ9Ik0xNCA2LjF2Mi4yNDMiIC8+CiAgPHBhdGggZD0ibTE1LjUgMTUuNTcxLS45NjQuOTY0YTUgNSAwIDAgMS03LjA3MSAwIDUgNSAwIDAgMSAwLTcuMDdsLjk2NC0uOTY1IiAvPgogIDxwYXRoIGQ9Ik0xNiA3VjNhMSAxIDAgMCAxIDEuNzA3LS43MDcgMi41IDIuNSAwIDAgMCAyLjE1Mi43MTcgMSAxIDAgMCAxIDEuMTMxIDEuMTMxIDIuNSAyLjUgMCAwIDAgLjcxNyAyLjE1MkExIDEgMCAwIDEgMjEgOGgtNCIgLz4KICA8cGF0aCBkPSJtMiAyIDIwIDIwIiAvPgogIDxwYXRoIGQ9Ik04IDE3djRhMSAxIDAgMCAxLTEuNzA3LjcwNyAyLjUgMi41IDAgMCAwLTIuMTUyLS43MTcgMSAxIDAgMCAxLTEuMTMxLTEuMTMxIDIuNSAyLjUgMCAwIDAtLjcxNy0yLjE1MkExIDEgMCAwIDEgMyAxNmg0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/candy-off
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const CandyOff = createLucideIcon('candy-off', __iconNode);

export default CandyOff;
