import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  [
    'path',
    {
      d: 'M18.715 13.186C18.29 11.858 17.384 10.607 16 9.5c-2-1.6-3.5-4-4-6.5a10.7 10.7 0 0 1-.884 2.586',
      key: '8suz2t',
    },
  ],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  [
    'path',
    { d: 'M8.795 8.797A11 11 0 0 1 8 9.5C6 11.1 5 13 5 15a7 7 0 0 0 13.222 3.208', key: '19dw9m' },
  ],
];

/**
 * @component @name DropletOff
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTguNzE1IDEzLjE4NkMxOC4yOSAxMS44NTggMTcuMzg0IDEwLjYwNyAxNiA5LjVjLTItMS42LTMuNS00LTQtNi41YTEwLjcgMTAuNyAwIDAgMS0uODg0IDIuNTg2IiAvPgogIDxwYXRoIGQ9Im0yIDIgMjAgMjAiIC8+CiAgPHBhdGggZD0iTTguNzk1IDguNzk3QTExIDExIDAgMCAxIDggOS41QzYgMTEuMSA1IDEzIDUgMTVhNyA3IDAgMCAwIDEzLjIyMiAzLjIwOCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/droplet-off
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const DropletOff = createLucideIcon('droplet-off', __iconNode);

export default DropletOff;
