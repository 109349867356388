import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M11 12h.01', key: '1lr4k6' }],
  ['path', { d: 'M13 22c.5-.5 1.12-1 2.5-1-1.38 0-2-.5-2.5-1', key: 'fatpdi' }],
  [
    'path',
    {
      d: 'M14 2a3.28 3.28 0 0 1-3.227 1.798l-6.17-.561A2.387 2.387 0 1 0 4.387 8H15.5a1 1 0 0 1 0 13 1 1 0 0 0 0-5H12a7 7 0 0 1-7-7V8',
      key: 'kehrqe',
    },
  ],
  ['path', { d: 'M14 8a8.5 8.5 0 0 1 0 8', key: '1imjx2' }],
  ['path', { d: 'M16 16c2 0 4.5-4 4-6', key: 'z0nejz' }],
];

/**
 * @component @name Shrimp
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTJoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xMyAyMmMuNS0uNSAxLjEyLTEgMi41LTEtMS4zOCAwLTItLjUtMi41LTEiIC8+CiAgPHBhdGggZD0iTTE0IDJhMy4yOCAzLjI4IDAgMCAxLTMuMjI3IDEuNzk4bC02LjE3LS41NjFBMi4zODcgMi4zODcgMCAxIDAgNC4zODcgOEgxNS41YTEgMSAwIDAgMSAwIDEzIDEgMSAwIDAgMCAwLTVIMTJhNyA3IDAgMCAxLTctN1Y4IiAvPgogIDxwYXRoIGQ9Ik0xNCA4YTguNSA4LjUgMCAwIDEgMCA4IiAvPgogIDxwYXRoIGQ9Ik0xNiAxNmMyIDAgNC41LTQgNC02IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/shrimp
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Shrimp = createLucideIcon('shrimp', __iconNode);

export default Shrimp;
