import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M10 17h.01', key: 'nbq80n' }],
  ['path', { d: 'M10 7v6', key: 'nne03l' }],
  ['path', { d: 'M14 7h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-2', key: '1x5o8m' }],
  ['path', { d: 'M22 11v2', key: '1wo06k' }],
  ['path', { d: 'M6 7H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2', key: '1mdjgh' }],
];

/**
 * @component @name BatteryWarning
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMTdoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xMCA3djYiIC8+CiAgPHBhdGggZD0iTTE0IDdoMmEyIDIgMCAwIDEgMiAydjZhMiAyIDAgMCAxLTIgMmgtMiIgLz4KICA8cGF0aCBkPSJNMjIgMTF2MiIgLz4KICA8cGF0aCBkPSJNNiA3SDRhMiAyIDAgMCAwLTIgMnY2YTIgMiAwIDAgMCAyIDJoMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/battery-warning
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const BatteryWarning = createLucideIcon('battery-warning', __iconNode);

export default BatteryWarning;
