import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  [
    'path',
    { d: 'M11 11.31c1.17.56 1.54 1.69 3.5 1.69 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1', key: '157kva' },
  ],
  ['path', { d: 'M11.75 18c.35.5 1.45 1 2.75 1 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1', key: 'd7q6m6' }],
  ['path', { d: 'M2 10h4', key: 'l0bgd4' }],
  ['path', { d: 'M2 14h4', key: '1gsvsf' }],
  ['path', { d: 'M2 18h4', key: '1bu2t1' }],
  ['path', { d: 'M2 6h4', key: 'aawbzj' }],
  [
    'path',
    { d: 'M7 3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1L10 4a1 1 0 0 0-1-1z', key: 'pr6s65' },
  ],
];

/**
 * @component @name Dam
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTEuMzFjMS4xNy41NiAxLjU0IDEuNjkgMy41IDEuNjkgMi41IDAgMi41LTIgNS0yIDEuMyAwIDEuOS41IDIuNSAxIiAvPgogIDxwYXRoIGQ9Ik0xMS43NSAxOGMuMzUuNSAxLjQ1IDEgMi43NSAxIDIuNSAwIDIuNS0yIDUtMiAxLjMgMCAxLjkuNSAyLjUgMSIgLz4KICA8cGF0aCBkPSJNMiAxMGg0IiAvPgogIDxwYXRoIGQ9Ik0yIDE0aDQiIC8+CiAgPHBhdGggZD0iTTIgMThoNCIgLz4KICA8cGF0aCBkPSJNMiA2aDQiIC8+CiAgPHBhdGggZD0iTTcgM2ExIDEgMCAwIDAtMSAxdjE2YTEgMSAwIDAgMCAxIDFoNGExIDEgMCAwIDAgMS0xTDEwIDRhMSAxIDAgMCAwLTEtMXoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/dam
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Dam = createLucideIcon('dam', __iconNode);

export default Dam;
