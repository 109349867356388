import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M11.1 7.1a16.55 16.55 0 0 1 10.9 4', key: '2880wi' }],
  ['path', { d: 'M12 12a12.6 12.6 0 0 1-8.7 5', key: '113sja' }],
  ['path', { d: 'M16.8 13.6a16.55 16.55 0 0 1-9 7.5', key: '1qmsgl' }],
  ['path', { d: 'M20.7 17a12.8 12.8 0 0 0-8.7-5 13.3 13.3 0 0 1 0-10', key: '1bmeqp' }],
  ['path', { d: 'M6.3 3.8a16.55 16.55 0 0 0 1.9 11.5', key: 'iekzv9' }],
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
];

/**
 * @component @name Volleyball
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEuMSA3LjFhMTYuNTUgMTYuNTUgMCAwIDEgMTAuOSA0IiAvPgogIDxwYXRoIGQ9Ik0xMiAxMmExMi42IDEyLjYgMCAwIDEtOC43IDUiIC8+CiAgPHBhdGggZD0iTTE2LjggMTMuNmExNi41NSAxNi41NSAwIDAgMS05IDcuNSIgLz4KICA8cGF0aCBkPSJNMjAuNyAxN2ExMi44IDEyLjggMCAwIDAtOC43LTUgMTMuMyAxMy4zIDAgMCAxIDAtMTAiIC8+CiAgPHBhdGggZD0iTTYuMyAzLjhhMTYuNTUgMTYuNTUgMCAwIDAgMS45IDExLjUiIC8+CiAgPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTAiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/volleyball
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Volleyball = createLucideIcon('volleyball', __iconNode);

export default Volleyball;
