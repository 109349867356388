import createLucideIcon from '../createLucideIcon';
import { IconNode } from '../types';

export const __iconNode: IconNode = [
  ['path', { d: 'M10 2v2.343', key: '15t272' }],
  ['path', { d: 'M14 2v6.343', key: 'sxr80q' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  ['path', { d: 'M20 20a2 2 0 0 1-2 2H6a2 2 0 0 1-1.755-2.96l5.227-9.563', key: 'k0duyd' }],
  ['path', { d: 'M6.453 15H15', key: '1f0z33' }],
  ['path', { d: 'M8.5 2h7', key: 'csnxdl' }],
];

/**
 * @component @name FlaskConicalOff
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMnYyLjM0MyIgLz4KICA8cGF0aCBkPSJNMTQgMnY2LjM0MyIgLz4KICA8cGF0aCBkPSJtMiAyIDIwIDIwIiAvPgogIDxwYXRoIGQ9Ik0yMCAyMGEyIDIgMCAwIDEtMiAySDZhMiAyIDAgMCAxLTEuNzU1LTIuOTZsNS4yMjctOS41NjMiIC8+CiAgPHBhdGggZD0iTTYuNDUzIDE1SDE1IiAvPgogIDxwYXRoIGQ9Ik04LjUgMmg3IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/flask-conical-off
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FlaskConicalOff = createLucideIcon('flask-conical-off', __iconNode);

export default FlaskConicalOff;
